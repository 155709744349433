.Deadlines {
    width: 100%;
    align-items: center !important;
}

.Deadlines > .Tools {
    width: 100%;
    align-items: center;
}

.Deadlines table {
    width: 100%;
    overflow-x: scroll;
}

.Deadlines th {
    text-align: start;
}