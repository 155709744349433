.Card {
    background-color: #ffffff;
    box-sizing: border-box;
    box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.2);
    border-radius: 3px;
    padding: 1em 2em;
}

.Card .Card-Title {
    font-size: 110%;
    margin: 0.2em 0 0.5em;
}