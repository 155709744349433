.SFStatus {
    position: relative;
}

.SFStatus .Content {
    align-items: flex-end;
    gap: 8px;
}

.SFStatus .Content img {
    height: 40px;
}

.SFStatus .Content span {
    text-align: end;
    font-size: 80%;
}