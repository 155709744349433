.Layout-Wrap {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: stretch;
}

.App-Top {
    padding: 16px;
    background-color: #f8f8f8;
    border-right: 1px solid rgb(175, 175, 175);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    position: sticky;
    top: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    gap: 1em;
    z-index: 1000;
    min-height: 90px;
}

.App-Top-Center {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: end;
    flex-grow: 1;
}

.App-Top-Bar {
    display: flex;
    height: 50px;
}

.App-Top-Left {
    width: 200px;
}

.App-Top-Right {
    display: flex;
    width: 200px;
    justify-content: end;
}

.App-Logo {
    margin-bottom: 20px;
}

.Stepper,
.Stepper .Step-Wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.Stepper .Line {
    width: 150px;
    height: 10px;
    background-color: #dddddd;
    border-radius: 5px;
}

.Stepper .Line.active {
    background-color: #34af3a;
}

.Stepper .Step {
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    border-radius: 50%;
    background-color: #dddddd;
    text-align: center;
    font-weight: 700;
    font-size: 130%;
    color: white;
    pointer-events: none;
}

.Stepper .Step a {
    color: inherit;
}

.Stepper .Step.active {
    background-color: #34af3a;
    pointer-events: all;
}

.Stepper .Step.current {
    border: 5px solid darkgreen;
    margin: -5px;
    pointer-events: none;
}

.App main {
    box-sizing: border-box;
    padding: 16px;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.App main>* {
    width: calc(100% - 400px);
}