.SelectBar {
    border: 1px solid #1D2850;
    border-radius: 5px;
    background-color: white;
}

.SelectBar button {
    border-radius: 0;
    border-right: 1px solid #1D2850;
    background-color: transparent;
    padding: 0.5em 1em;
    font-size: 1em;
    font-weight: 400;
    color: inherit;
}

.SelectBar button:last-child {
    border-right: none;
}

.SelectBar button.active {
    color: white;
    background-color: #1D2850;
    pointer-events: none;
}