.Eingaenge td {
        text-align: end;
}

.Eingaenge td:first-child {
    text-align: start;
}

.Eingaenge tbody tr:nth-child(2) td {
    border-bottom: 2px solid black;
}