a {
  display: block;
  color: black;
}

.CSSBG {
  background-size: cover;
  background-position: center;
}

button {
  cursor: pointer;
  color: white;
  font-weight: bold;
  background-color: #1D2850;
  text-transform: uppercase;
  border: none;
  border-radius: 2px;
}

button.light {
  color: #1D2850;
  background-color: white;
  border: 1px solid #1D2850;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

button.Text {
  padding: 1em 4em;
}

button.Green {
  background-color: #34af3a;
}

select {
  border: 2px solid black;
  color: white;
  background-color: black;
  padding: 1em;
  font-family: Barlow;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 0.5em;
}

tr.ClickableRow {
  cursor: pointer;
}

tr.ClickableRow svg {
  color: transparent;
}

tr.ClickableRow:hover {
  background-color: #34af3a;
}

tr.ClickableRow:hover svg {
  color: white;
}

.FlexColumn {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
}

.FlexColumn h2 {
  margin-bottom: 0;
}

.FlexList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.FlexList.Center {
  align-items: center;
}

.FlexList.End {
  width: 100%;
  justify-content: flex-end;
}

.FlexList.BigGap {
  gap: 24px;
}

.Stretch {
  align-items: stretch;
}

input,
textarea {
  padding: 0.5em;
}